/** Import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { useState }             from "react";

/** @import hooks */

// import { qtySum }               from "../../../Hooks/QtySum";

/** Import models */

import Product                  from "../../../Models/SingleTyre";

/** Import components */

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

import SingleProductLayout      from "../../../Components/Products/Shared/Single/Layout";

import Description              from "../../../Components/Products/Shared/Single/Description";

import SingleProductData        from "../../../Components/Products/Shared/Single/SingleProductData";

import Price                    from "../../../Components/Products/Shared/Single/Price";

// import Stock                    from "../../../Components/Products/Shared/Single/Stock";

import Features                 from "../../../Components/Products/Tyres/Single/Features";

import Properties               from "../../../Components/Products/Shared/Single/Properties";

import Cart                     from "../../../Components/Products/Tyres/Single/Cart";

import Compare                  from "../../../Components/Products/Shared/Single/Compare/Compare";

import Title                    from "../../../Components/Products/Shared/Single/Title";

import Specifications           from "../../../Components/Products/Shared/Single/Specifications";

import ActionNotification       from "../../../Components/Products/Shared/Single/ActionNotifications";

import Delivery                 from "../../../Components/Products/Shared/Single/Delivery";

import SeasonIcons              from "../../../Components/Products/Tyres/Catalog/Item/Season";

import MontageNotification      from "../../../Components/Products/Shared/Single/Montage";

/**
 * 
 * @returns Single tyre page
 * 
 */

const SingleTyre = () => {  const 
    
    { t }                   = useTranslation    (),

    productId               = useParams         ().productId,

    { loading, data }       = Product           ( productId ),

    [ 
        
        cartNotification, 
        
        setCartNotification ] = useState ( [] ),

    [ compare, setCompare ] = useState ( [] ),

    [ showMontage, setShowMontage ] = useState ( false ),

    /** @returns Loaded product data and components */

    LoadProduct = () => {

        if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node;

        return <SingleProductLayout data={ product }>

            <SingleProductData data={ { 
                
                image : product.currentImage, 
                
                model : `${ product.manufacturer.name.toUpperCase () } ${ product.model }`,
                
                type : product.productType } }>

                    <Title product={ product } />

                    <Price price={ parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) } />

                    {/* <Stock quantity={ qtySum ( product.productVendors ) } /> */}

                    <Delivery product={ product } />

                    <SeasonIcons season={ product.season } />

                    <Properties product={ product } />

                    <Features product={ product } />

                    { data.product.edges[ 0 ].node.productVendors[ 0 ].qty > 0 && 
                    
                        <MontageNotification />   
                    
                    }

                    { cartNotification.length > 0 && 

                        <ActionNotification data={ cartNotification } /> }

                    <Cart product={ product } notifications={ { view: cartNotification, set: setCartNotification } } />

                    <Compare 
                    
                        productId={ product.id } 
                    
                        type={ product.productType } 
                    
                        notifications={ { view: compare, set: setCompare } } />

            </SingleProductData>

            <Specifications data={ [

                { name: t ( "manufacturer" ), value: product.manufacturer.name, style: "uppercase" },

                { name: t ( "model" ), value: product.model, style: "regular" },
                
                { name: t ( "vehicle_type" ), value: product.vehicleType, style: "regular" },

                { name: t ( "width" ), value: product.width, style: "regular" },

                { name: t ( "height" ), value: product.height, style: "regular" },

                { name: t ( "diameter" ), value: `R${ parseInt ( product.diameter ) }${ product?.isCTire ? "C" : "" }`, style: "regular" },

                { name: t ( "season" ), value: t ( product.season ), style: "regular" },

                { name: t ( "speed_rating" ), value: product.speedRating, style: "uppercase" },

                { name: t ( "load_index" ), value: product.loadIndex, style: "regular" },

                { name: t ( "noise_index" ), value: product.noiseIndex, style: "regular" },

                { name: t ( "fuel_efficiency" ), value: product.fuelEfficiency, style: "uppercase" },

                { name: t ( "wet_breaking" ), value: product.wetBreakingEfficiency, style: "uppercase" },

                { name: t ( "manufacturing_year_dot" ), value: product?.year || "-", style: "uppercase" },

                { name: "OE", value: product.specifications.join ( ", " ) }

            ] } />

            <Description description={ product.description } />

        </SingleProductLayout>

    };

    /** @return component */

    return ( <div className="container max-content-width">

        { ! loading && 

        <Breadcrumbs collection={ [

            { name: t ( "tyres" ), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }` },

            { name: data.product.edges[ 0 ].node.manufacturer.name.toUpperCase (), URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }/${

                new URLSearchParams ( { f: 12, m: data.product.edges[ 0 ].node.manufacturer.name } ).toString ()

            }` },

            { name: `${data.product.edges[ 0 ].node.model} - ${ data.product.edges[ 0 ].node.width } | ${ data.product.edges[ 0 ].node.height } | R${ parseInt ( data.product.edges[ 0 ].node.diameter ) }${ data.product.edges[ 0 ].node?.isCTire ? "C" : "" } | ${ data.product.edges[ 0 ].node.loadIndex }${ data.product.edges[ 0 ].node.speedRating.toUpperCase () }`, URI: `/${ t ( "__permalink_shop__" ) }/${ t ( "__permalink_products__" ) }/${ t ( "__permalink_tyres__" ) }` }

        ] } type="basic" /> }

        <LoadProduct />
            
    </div> );

}

/** @export component */
 
export default SingleTyre;